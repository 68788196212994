.header {
  display: flex;
  align-items: center;
  height: 4.25rem;
  padding: 0.75rem 1rem;

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  &__grid {
    height: 40px;
    margin-left: auto;
  }

  &__logo {
    display: flex !important;
    align-items: center;
    justify-content: center;

    margin: auto;

    border-radius: 10px;
    outline: none;
  }

  &__logo:focus,
  &__logo:focus-visible {
    box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(196, 181, 253, 0.7),
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 768px) {
    padding: 0.75rem;

    &__grid {
      display: flex;
    }
  }
}

.header__menu {
  display: flex;
  height: 40px;

  a {
    display: block;
  }

  .learn-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: 0 0 0 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.25rem;

    outline: none;
    border-radius: 8px;
    transition: background-color 0.15s ease;
    will-change: background-color, color;

    font-size: 0.9375rem;
    font-weight: 500;
    font-family: var(--font-family);
    color: #e5e4fa;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: #3e3e52;
      color: #fff;
    }

    @media screen and (max-width: 1400px) {
      display: none;
    }

    &__icon {
      margin-right: 0.25rem;
    }
  }

  @media screen and (max-width: 768px) {
    img {
      height: 40px;
      width: 40px;
    }
  }
}

.header__links {
  margin-left: 1rem;

  @media screen and (max-width: 1600px) {
    margin-left: 0;
  }
}

.wallet-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  line-height: 1.125rem;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left: 12px;
    padding: 0 1rem;
    border-radius: 70px;
    font-size: 0.9325rem;
  }
}

.account {
  height: 40px;
}

.account__button {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid var(--surface-card);
  background-color: #24243a;
  color: rgb(255, 255, 255);
  font-weight: 500;
  padding: 0.5rem 0.8rem;
  border-radius: 14px;
  height: 36px;
  align-items: center;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 768px) {
    align-items: center;
    height: 40px;
    padding-left: 1rem;
    margin: 0;
    border-radius: 51px;
    font-size: 0.9325rem;
    font-weight: 500;
  }
}

.faucet-button {
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  animation: anime 16s linear infinite;
  border-color: transparent !important;

  &:hover {
    box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgb(196 181 253 / 70%),
      0 1px 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__inner {
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
}

.grid-child {
  &__first-row {
    @media screen and (max-width: 1199px) {
      grid-row: 1/2;
    }
  }
}

@keyframes anime {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 0 1rem;

  @media screen and (max-width: 1600px) {
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 1400px) {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: max-content;
    padding: 0;
    margin: 0;

    list-style-type: none;
  }

  &__list-item {
    position: relative;

    height: 40px;
    margin-right: 32px;

    border-radius: 8px;

    font-weight: 500;
    font-family: var(--font-family);

    transition: background-color 0.15s ease;
    will-change: background-color, color;

    &.active .header__link,
    &:hover .header__link {
      background-color: #4a495e;
      color: #fff;
    }

    &::before {
      position: absolute;
      top: 8px;
      left: -16px;

      display: block;
      width: 0;
      height: 24px;

      border-right: 1px solid #494954;
      border-radius: 8px;
      content: "";
    }

    @media screen and (max-width: 1600px) {
      margin-right: 8px;
      border-right: none;

      &::before {
        display: none;
      }
    }
  }
}

.link.header__link {
  display: flex;
  align-items: center;

  height: 100%;
  padding: 0.5rem;

  border-radius: 8px;

  font-size: 0.9375rem;
  color: #e5e4fa;

  transition: background-color 0.15s ease;
  will-change: background-color;

  &.link_type_button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 40px;
    border: none;

    cursor: pointer;
    background-color: transparent;

    font: 500 0.9375rem/1 var(--font-family);

    & .link__angle {
      width: 10px;
      height: 6px;
      margin-left: 0.25rem;

      mask: no-repeat center/contain url("../../img/angle.svg");
      background-color: #d4c4ed;
      transition: transform 0.15s ease;
      will-change: transform, background-color;

      &_toggle_yes {
        transform: rotate(-180deg);
      }

      .active & {
        background-color: var(--primary-color-text);
      }
    }

    .links__list-item.active &,
    .links__list-item:hover &,
    .links__list-item:focus &,
    .links__list-item:focus-visible & {
      color: var(--primary-color-text);
      background-color: #4a495e;
    }
  }

  &.active,
  &:hover,
  &:focus,
  &:focus-visible {
    color: var(--primary-color-text);
  }

  &:focus,
  &:focus-visible {
    outline: none;
    background-color: #3e3e52;
  }

  & .link__icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background-color: #d4c4ed;

    will-change: background-color;

    &_type_swap-wide {
      mask-image: url("../../img/swap-wide.svg");
    }

    &_type_earn {
      mask-image: url("../../img/earn.svg");
    }

    &_type_bridge {
      mask-image: url("../../img/bridge.svg");
    }

    &_type_stats {
      mask-image: url("../../img/stats.svg");
    }

    &_kana-swap {
      mask-image: url("../../img/kana-swap.svg");
    }

    .active & {
      background-color: var(--primary-color-text);
    }
  }
}

.link__dropdown.p-menu {
  padding: 0.75rem 0.5rem;

  border-radius: 0.75rem !important;
  background-color: #28253e !important;

  & .p-menuitem-link {
    padding: 0.75rem;

    border-radius: 0.5rem;

    transition: background-color 0.15s ease;
    will-change: background-color;

    &.active,
    &:hover {
      background-color: #3e3e52;

      & .p-menuitem-text {
        color: var(--primary-color-text);
      }
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    & .p-menuitem-text {
      color: #fbfaff;

      transition: color 0.15s ease;
      will-change: color;
    }
  }
}

.earn-link {
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  background-color: #d4c4ed;

  &_type_pools {
    width: 29px;
    height: 29px;
    margin-right: 1rem !important;

    mask-image: url("../../img/pools.svg");
  }

  &_type_farms {
    width: 32px;
    height: 26px;
    margin-right: 0.625rem !important;

    background: no-repeat center/contain url("../../img/farms.svg");
  }

  &_type_layerzero {
    width: 32px;
    height: 26px;
    margin-right: 0.625rem !important;

    background: no-repeat center/contain url("../../img/layerzero-header.svg");
  }

  &_type_wormhole {
    width: 32px;
    height: 26px;
    margin-right: 0.625rem !important;

    background: no-repeat center/contain url("../../img/wormhole-header.svg");
  }

  &_type_staking {
    width: 22px;
    height: 22px;
    margin-right: 1.3125rem !important;

    mask-image: url("../../img/staking.svg");
  }

  &_type_kana-swap {
    width: 22px;
    height: 22px;

    mask-image: url("../../img/kana-swap.svg");
  }

  &_type_swap-wide {
    width: 22px;
    height: 22px;

    mask-image: url("../../img/swap-wide.svg");
  }

  .active & {
    background-color: var(--primary-color-text);
  }
}

.account {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  white-space: nowrap;
  height: 40px;
  background: var(--surface-card);
  color: white;
  padding-left: 0.8rem;
  font-weight: 500;

  &__identicon {
    margin-left: 5px;
  }

  &__button {
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid var(--surface-card);
    background-color: #24243a;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding: 0.5rem 0.8rem;
    border-radius: 14px;
    height: 36px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-right: 2px;
    margin-left: 0.8rem;
  }

  @media screen and (max-width: 768px) {
    padding-left: 1rem;

    &__button {
      margin-left: auto;
    }
  }
}

.p-button.social-button {
  width: 40px;
  height: 40px !important;
  padding: 0;
  border: none;

  color: var(--gray-300) !important;
  background-color: #14142f !important;

  transition: color, background-color 0.15s ease;

  &:enabled:hover,
  &:enabled:active,
  &:enabled:focus,
  &:enabled:focus-visible {
    color: var(--primary-color-text);
    background-color: #3e3e52 !important;
  }

  &:enabled:focus {
    box-shadow: none;
  }

  & .pi-ellipsis-h {
    display: none;
  }

  &.mobile-menu {
    display: none !important;

    @media screen and (max-width: 1400px) {
      display: flex !important;
    }
  }

  @media screen and (max-width: 1400px) {
    display: none !important;
  }
}

.social-button__icon {
  width: 100%;
  height: 100%;

  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  background-color: #d4c4ed;

  transition: background-color 0.15s ease;
  will-change: background-color;

  &_type_ellipsis {
    height: 1.5rem;
    mask-image: url("../../img/ellipsis.svg");
  }

  .social-button:hover &,
  .social-button:active &,
  .social-button:focus &,
  .social-button:focus-visible & {
    background-color: var(--primary-color-text);
  }
}

.p-menu.social-menu {
  padding: 0.5rem;

  border-radius: 0.75rem !important;

  background-color: #28253e !important;
}

.p-menuitem-link.social-menu__item {
  border-radius: 0.5rem;

  transition: background-color 0.15s ease;
  will-change: background-color;

  &:hover {
    background-color: #3e3e52 !important;
  }
}

.buy-apt-card {
  &__component {
    // .buy-apt-card__component
    position: relative;

    display: flex;

    padding: 0.75rem 0.5rem;

    cursor: pointer;
    border-radius: 0.5rem;

    transition: background-color 0.15s ease;
    will-change: background-color;

    &:hover {
      background-color: #3e3e52;
    }
  }

  &__img {
    // .buy-apt-card__img
    align-self: start;
  }

  &__info-wrapper {
    // .buy-apt-card__info
    display: flex;
    flex-direction: column;

    margin-left: 1rem;

    font-family: var(--font-family);
    line-height: 24px;
  }

  &__title {
    // .buy-apt-card__title
    margin: 0;

    color: #e5e4fa;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  &__description {
    // .buy-apt-card__description
    margin: 0;

    opacity: 0.6;

    color: white;
    font-size: 13px;
    line-height: 24px;
  }

  &__fee {
    // .buy-apt-card__fee
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    padding: 0 0.5rem;
    border: 1px solid #2c2a40;

    border-radius: 0.5rem;
    font-size: 13px;
    font-weight: 600px;
    line-height: 24px;
    letter-spacing: -0.43%;

    &-label {
      margin-right: 0.25rem;

      color: #9594aa;
    }

    &-value {
      color: #e5e4fa;
    }
  }
}

.sidebar {
  max-width: 390px;
}

.p-sidebar-mask {
  z-index: 10;
}
.p-sidebar-content {
  padding: 0px !important;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.p-sidebar {
  width: 524px !important;
  background: #16162d;
  box-shadow: -2px 0px 4px #000000;
  border-width: 0;
  z-index: 99;

  @media (max-width: 524px) {
    width: 100% !important;
  }
}
.dark-background {
  background: #03031c;
}
.p-sidebar-header {
  display: none;
}
.p-sidebar {
  ::-webkit-scrollbar {
    width: 12px;
    height: 280px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e5e4fa30;
    opacity: 0.3;
    border-radius: 40px;
  }
}

.mobile-menu {
  &.p-menu {
    background-color: #16162d !important;
    width: auto;
    border: 0px;
    border-radius: 0;
    margin: 0rem -1.25rem;
    padding: 0px;

    .p-menuitem {
      &:not(:last-child) {
        border-bottom: 1px solid #e5e4fa1a;
      }

      .p-menuitem-link,
      .p-accordion-header-link {
        padding: 20px 24px 20px 20px;

        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.45%;

        &.active,
        &:hover {
          background-color: #2b2b40 !important;
        }

        &:focus {
          box-shadow: none !important;
        }

        & .p-menuitem-icon {
          width: 24px;
          height: 24px;
          margin-right: 20px;

          font-size: 24px;
          color: #d4c4ed;
        }

        & .buy-apt-icon {
          width: auto !important;
          height: 18px !important;
          margin-right: 0 !important;
        }
      }

      .buy-apt-card__component {
        padding: 12px 20px;
      }

      .buy-apt-accordion {
        padding: 0;
      }

      .active {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }
  }

  .p-menuitem {
    padding: 0px 16px;
  }

  .p-toggleable-content {
    border-top: 1px solid #e5e4fa1a;
  }
}

.menu-sub-title {
  padding: 8px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #e5e4fa;
  background-color: #03031c;
}

.divider {
  border: 0px;
  border-top: 2px solid #fbfaff16;
  border-width: 2px 0px 0px 0px;
  margin: 0px -1.25rem;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;

  .menu-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.45%;
    color: #e5e4fa;
  }

  & > div:last-child {
    margin-left: auto;
  }
}

.menu-settings {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;

  .button {
    justify-content: center;

    width: 48px;
    height: 48px;
    padding: 0.5rem;
    color: #e5e4fa;

    border: none;
    border-radius: 13px;
    background-color: #24243a;

    &:hover {
      color: white;
    }

    & img,
    & .pi {
      width: 1.2rem;
    }
  }
}

.menu-link {
  width: 24px;
  height: 24px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &_type_swap {
    background-image: url("../../img/swap-wide.svg");
  }

  &_type_pools {
    background-image: url("../../img/pools.svg");
  }

  &_type_stats {
    background-image: url("../../img/stats.svg");
  }

  &_type_bridge {
    background-image: url("../../img/bridge.svg");
  }

  &_type_stakes {
    background-image: url("../../img/farms.svg");
  }

  &_type_staking {
    background-image: url("../../img/staking.svg");
  }

  &_type_kana-swap {
    background-image: url("../../img/kana-swap.svg");
  }
}
.hide-desktop-airdrop-button {
  @media screen and (max-width: 1400px) {
    display: none;
  }
}

.airdrop-wrapper {
  display: flex;
  padding: 13px 16px;
}

.airdrop-mobile {
  width: 100%;
  padding: 12px;
  gap: 12px;
  justify-content: flex-start;
}
