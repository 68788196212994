.bg-widgetBg {
  .text-black {
    color: white;
  }

  img[src="data:image/svg+xml,%3csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.25 0.5C4.42266 0.5 3.75 1.17266 3.75 2V7.25C3.75 8.07734 4.42266 8.75 5.25 8.75H10.5C11.3273 8.75 12 8.07734 12 7.25V2C12 1.17266 11.3273 0.5 10.5 0.5H5.25ZM1.5 4.25C0.672656 4.25 0 4.92266 0 5.75V11C0 11.8273 0.672656 12.5 1.5 12.5H6.75C7.57734 12.5 8.25 11.8273 8.25 11V9.5H6.75V11H1.5V5.75H3V4.25H1.5Z' fill='%2300F9A9'/%3e%3c/svg%3e"] {
    filter: brightness(0) saturate(100%) invert(28%) sepia(74%) saturate(1761%) hue-rotate(242deg) brightness(87%) contrast(91%);
  }
}

.grid-cols-2 {
  .text-black {
    color: white;
  }

  div:first-of-type {
    border-color: #6e42ca;
  }
}

.rounded-3xl.border-transparent.my-2.cursor-pointer {
  border-color: #6e42ca;
}

.absolute.top-0.flex.px-3.rounded-lg {
  background-color: #2c2533;

  img {
    filter: brightness(0) saturate(100%) invert(28%) sepia(74%) saturate(1761%) hue-rotate(242deg) brightness(87%) contrast(91%);
  }
  div {
    color: #6e42ca;
    background-image: none;
  }
}

.text-white.bg-widgetBg.p-5.h-fit.rounded-3xl.border-2 {
  @media screen and (max-width: 400px) {
    width: 100%;
  }
}

.bridge-wrapper {
  max-width: 600px;

  margin: 0 auto;

  .bridge-logos {
    margin: 1rem auto 2rem;

    @media (max-width: 768px) {
      margin: 0.5rem auto 0.5rem;
    }
  }
}



