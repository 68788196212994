.buttonAirdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 8px;
  border-radius: 8px;
  background: #1c1c33;
  border: none;
  cursor: pointer;

  &:hover {
    background: #3e3e52;
  }

  span {
    color: #fbfaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    font-family: var(--font-family);
  }
}
