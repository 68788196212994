.banner {
  border-radius: 10px;
  position: relative;
  height: 84px;
  margin-top: 1rem;

  .wrapper {
    z-index: 1;
    position: absolute;
    background: #abaeff21;
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: rotate(180deg);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      width: 200%;
      height: 110%;
      background: radial-gradient(
        46.65% 93.31% at 49.27% 106.09%,
        #5c308e 25.09%,
        rgba(55, 44, 123, 0.5) 76.41%,
        rgba(53, 42, 119, 0.3999) 81.13%,
        rgba(46, 37, 106, 0.2962) 86.02%,
        rgba(35, 28, 86, 0.1909) 90.99%,
        rgba(19, 15, 57, 0.0851) 95.99%,
        rgba(3, 3, 28, 0) 100%
      );
      opacity: 0.4;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contentText1 {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    color: #ffffff;

    span {
      border: 1px solid #8c55ff;
      border-radius: 2px;
      padding: 1px 3px;
      margin-left: 4px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  .contentText2 {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

    margin-top: 5px;

    color: #ffffff;
    width: 100%;
  }

  .etheriumLogo {
    z-index: 0;
    width: 70px;
    height: 50px;
    top: -10px;
    left: 65%;
    position: absolute;
    @media (max-width: 500px) {
      width: 60px;
      height: 40px;
      top: -5px;
      left: 75%;
    }
  }

  .solanaLogo {
    z-index: 0;
    width: 85px;
    height: 45px;
    bottom: -5px;
    left: 75%;
    position: absolute;
    @media (max-width: 500px) {
      width: 70px;
      height: 40px;
      bottom: -5px;
      left: 85%;
    }
  }

  img {
    min-width: 60px;
    position: relative;
  }

}

.logosImage {
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  border-radius: 10px;
}
