.container {
  margin-top: 56px;
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }
  @media (min-width: 1440px) {
    justify-content: center;
  }
}

.socialIcons {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  gap: 24px;
  order: 2;
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
  @media (min-width: 640px) {
    gap: 40px;
  }
  @media (min-width: 1024px) {
    width: 100px;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 45px;
    align-items: flex-start;
  }
  @media (min-width: 1440px) {
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: 0;
    padding-right: 33px;
    transform: translate(0%, -50%);
    gap: 40px;
    width: initial;
    img {
      color: white;
      width: 20px;
      cursor: pointer;
      opacity: 0.6;
      transition: 0.25s;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  gap: 16px;
  margin-bottom: 32px;
  order: 1;
  @media (min-width: 1024px) {
    width: 215px;
    align-items: flex-start;
    border-right: 1px solid hsla(243, 69%, 94%, 0.2);
  }
  @media (min-width: 1440px) {
    flex-direction: row;
    width: initial;
    border: 0;
    margin-bottom: 16px;
  }
  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(#fbfaff, 0.6);
    &:hover,
    &:focus,
    &:focus-visible {
      color: var(--primary-color-text);
    }
  }
}
.feedback {
  padding: 0;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    width: 0;
    border: 0;
  }
}

.copyright {
  max-width: 318px;
  margin: 0 auto;
  padding: 0 24px 24px;
  text-align: center;
  order: 3;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(#fbfaff, 0.5);
  @media (min-width: 640px) {
    min-width: 100%;
  }
  @media (min-width: 1024px) {
    margin: 0;
    text-align: start;
  }
  @media (min-width: 1440px) {
    margin: 0 auto;
    text-align: center;
  }
  a {
    color: rgba(#fbfaff, 0.5);
    &:visited {
      color: rgba(#fbfaff, 0.5);
    }
    &:hover {
      color: #ffffff;
    }
  }
}
