.banner {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(40, 37, 62, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;
  column-gap: 24px;
  z-index: 10;
  border-radius: 24px;
  min-width: 340px;

  .title {
    font-family: Roboto, sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0;
    text-align: center;
    padding: 0 10px;
    margin-top: 18px;
  }

  .image {
    width: 77px;
    height: auto;
    margin-top: 23px;
  }

  .button {
    width: 196px;
    height: 48px;
    padding: 0 24px;
    border-radius: 24px;
    font-size: 1em;
    font-weight: 500;
    color: white;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #6E42CA 0%, #8D29C1 100%);
    margin-top: 23px;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #8d6ad5 0%, #6f42ca 100%)
    }
  }

  .bannerClose {
    position: absolute;
    top: 18px;
    right: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    color: rgba(#fff, 0.8);
  }
}