@import 'primereact/resources/themes/lara-dark-purple/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

.App {
  margin: 0;
}

.app-container {
  max-width: 100vw;
  min-height: 100vh;
  background-color: #020211;
  background-image: url(../img/bridge-bg.svg);
  background-position: top center;
  background-repeat: no-repeat;
  animation: fade 1s;
  position: relative;

  @media (min-width: 1400px) {
    background-size: 100%;
  }

  @media (max-width: 1024px) {
    background-position: bottom center;
  }

  @media (max-width: 640px) {
    background-image: none;
  }
}

.app-layout {
  display: grid;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
}

.page {
  max-width: 600px;
  margin: 0 auto;

  .bridge {
    margin: 0.5rem auto 4rem;

    @media (max-width: 768px) {
      margin: 0.5rem auto 0.5rem;
    }
  }

  .bridge-text {
    text-align: center;
    margin: 1rem 0 2rem;
    color: rgba(251, 250, 255, 0.6);
  }
}

.bridge-logos {
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;

  &__image {
    height: 100%;
    width: auto;
    margin: 0 20px;
  }

  &__text {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color-text);
  }
}

.p-menu.p-menu-overlay {
  overflow: hidden !important;
  border: 0;
  border-radius: 24px;
  background: var(--surface-card);
  box-shadow: 0 0 1px rgb(0 0 0 / 1%), 0 4px 8px rgb(0 0 0 / 4%), 0 16px 24px rgb(0 0 0 / 4%), 0 24px 32px rgb(0 0 0 / 1%);
}

.p-menu {
  width: 13.5rem;
}

.link {
  text-decoration: none;
  font-size: .9375rem;
  color: #fbfaff;
  line-height: 18px;
}

.p-button {

}

.page aptos-bridge>div {
  background: #1d1d2f !important;
  padding: 24px 0 !important;
  border-radius: 24px !important;
}

.page aptos-bridge>div>.MuiContainer-root {
  padding: 0 16px !important;
}

.page aptos-bridge>div>.MuiContainer-root>.MuiBox-root:last-child>div:last-child {
  margin-top: 16px;
}

.page aptos-bridge>div>.MuiContainer-root>.MuiBox-root>div>button:last-of-type {
  color: white;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 16px;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;

  .p-button-label {
    font-weight: 500;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.p-button-secondary {
    background: #43395b;
    color: white;
    border-width: 0px;
    border-radius: 100px;

    &:enabled:hover {
      background: #483a6b;
      color: white;
    }

    &:enabled:active {
      background: #3c3257;
      color: white;
    }

    &:enabled:focus {
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  &:enabled:hover {
    color: white;
  }

  &:enabled:active {
    color: white;
  }

  &.p-button-primary {
    position: relative;
    border-width: 0px;
    border-radius: 100px;
    background-image: linear-gradient(90deg, #6e42ca 0%, #8d29c1 100%);
    z-index: 1;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg, #6039b0 0%, #8d29c1 30.44%);
      z-index: -1;
      transition: all 0.25s ease-in-out;
      opacity: 0;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg, #8d6ad5 0%, #6f42ca 100%);
      z-index: -1;
      transition: all 0.25s ease-in-out;
      opacity: 0;
    }

    &:active::after {
      opacity: 1;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  &:enabled:hover {
    color: white;
    background-color: #8d6ad5;
  }

  &:enabled:active {
    color: white;
    background-color: #8d6ad5;
  }
}

.page aptos-bridge>div>.MuiContainer-root>.MuiBox-root:last-child>div:last-child>button:hover {
  color: #000000;
}

.page aptos-bridge>div>.MuiContainer-root>.MuiBox-root {
  @media (max-width: 640px) {
    width: 100% !important;
  }
}


.page aptos-bridge>div>.MuiContainer-root>.MuiBox-root>div>div {
  border-radius: 20px;
}

.tutorial-container {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #16162d;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;

  p {
    margin-left: 1rem;
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

  }

  .tutorial-button {
    cursor: pointer;
    display: flex;
    width: 40%;
    padding: 0.5rem 5rem;
    margin-top: 0px;
    margin-left: 16px;
    border: 2px solid #6e42ca;
    border-radius: 100px;
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #6e42ca;
    background: transparent;
    align-items: center;
    justify-content: center;
    transition: .25s;


    &:hover {
      background-color: #6e42ca20;
    }

    @media screen and (max-width: 900px) {
      width: 30%;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
